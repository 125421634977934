import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';

// warning: only for lazy load image requirement!!

const StaticImage = ({filename, extraStyle = {}}) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Img
          fluid={
            data.allImageSharp.edges.find((element) => {
              // Match string after final slash
              return element.node.fluid.src.split('/').pop() === filename;
            }).node.fluid
          }
          style={extraStyle}
        />
      );
    }}
  />
);

export default StaticImage;
